
<!--
TODO: handle vuelidate error
-->

<template>
  <v-card flat width="400px">
    <v-card-title class="justify-center"> Create New Account </v-card-title>
    <v-card-text>
      <v-form>
        <v-alert
          v-show="isShowAlertCreate"
          text
          type="error"
          transition="fade-transition"
          >{{ createalertmessage }}</v-alert
        >
        <!-- <v-text-field label="hidden" style="display: none"></v-text-field>
        <v-text-field label="hidden" style="display: none"></v-text-field> -->

        <InputTextField label="FIRST NAME" v-model="$v.register.first_name" />
        <InputTextField label="LAST NAME" v-model="$v.register.last_name" />
        <InputTextField label="COMPANY" v-model="$v.register.company" />
        <InputTextField
          label="EMAIL ADDRESS"
          v-model="$v.register.email"
        />
        <SecretTextField label="PASSWORD" v-model="$v.register.password" />

        <v-btn
          class="mt-4"
          depressed
          block
          large
          color="primary"
          :disabled="$v.register.$invalid"
          @click="submitCreate"
          >CREATE</v-btn
        >
      </v-form>
    </v-card-text>
    <v-card-text>
      <NavigationButton to="/login">Back To Login</NavigationButton>
    </v-card-text>
  </v-card>
</template>

<script>

import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {

  data() {
    return {
      register: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        company: null,
      },

      isShowAlertCreate: false,
      createalertmessage: "",
    }
  },

  mixins: [validationMixin],
  validations: {
    register: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      password: {
        required,
        minLength: minLength(8),
        validate: (value) => {
          var strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
          );
          return strongRegex.test(value);
        },
      },
      company: { required },
    }
  },

  methods: {
    async submitCreate() {
      if (this.$v.register.$invalid) {
        this.$v.register.$touch();
        return
      }

      this.$store.commit("set", ["modalSpinnerText", "Creating..."]);
      this.$store.commit("toggle", "showHideModalSpinner");
      this.isShowAlertCreate = false;
      this.createalertmessage = "";

      try {
        await this.$http.post('/api/user', this.register)
        // TODO: request to have request and renew token returned after register
        await this.login(this.register.email, this.register.password)
      } catch (error) {
        if (error.response) {
          this.isShowAlertCreate = true;
          if (typeof error.response.data.Error === "undefined") {
            this.createalertmessage = error.response.data.message;
          } else {
            this.createalertmessage = error.response.data.Error;
          }
        }
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },

    async login(email, password) {

      const response = await this.$http.post('/api/user/login', { email, password })
      const timeRequestToken = response.data.requestToken_Expiration * 1000;
      const timeRenewToken = response.data.renewToken_Expiration * 1000;
      const datetimeRequestToken = new Date(timeRequestToken);
      const datetimeRenewToken = new Date(timeRenewToken);

      this.$cookies.set('requestToken', response.data.requestToken, datetimeRequestToken)
      this.$cookies.set('renewToken', response.data.renewToken, datetimeRenewToken)
      this.$router.push('/')
    },
    registerErrorMessage(fieldName) {
      if (!this.$v.register[fieldName].$dirty) {
        return null;
      }

      if (!this.$v.register[fieldName].required) {
        return "Required field";
      }

      if (fieldName == "email" && !this.$v.register[fieldName].email) {
        return "Must be a valid email";
      }

      if (fieldName == "password" && !this.$vregister[fieldName].validate) {
        return "Password must be at least 8 characters containing at least an uppercase letter and a number";
      }

      return null;
    },
  }
  
}
</script>